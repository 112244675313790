<script>
  import CardBarChart from "../../components/Cards/CardBarChart.svelte";
  import { t, locale, locales } from "../../i18n";
  // core components
  export let location;
</script>

<div>

  <div class="flex flex-wrap">
    <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
     <CardBarChart />
    </div>
  </div>
</div>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-12/12 px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg  bg-red-300 border-0"
      >
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">

           <hr class="mt-6 border-b-1 border-blueGray-700" />

            <div class="relative w-full mb-3 text-black ">



              <p>{@html  $t("intro_p0")}<a href="https://en.wikipedia.org/wiki/Great_Firewall"> {@html  $t("intro_p1")} </a></p>

              <p>{@html  $t("intro_p2")}</p>

              <p>{@html  $t("intro_p3")}</p>

              <p>{@html  $t("intro_p4")}</p>

              <p>{@html  $t("intro_p5")}</p>


            </div>

            <hr class="mt-6 border-b-1 border-blueGray-700" />
           
        </div>
      </div>
    </div>
  </div>
</div>
