<script>
  import { t, locale, locales } from "../../i18n";
  // make dynamic date to be added to footer
  let date1 = new Date().getFullYear();
  let date = '2005 - ' + date1;

</script>

<footer class="block py-4">
  <div class="container mx-auto px-4">
    <hr class="mb-4 border-b-1 border-blueGray-200" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4">
        <div
          class="text-sm text-blueGray-500 font-semibold py-1 text-center md:text-left"
        >
          {@html  $t("copyright")} © {date}
          {@html  $t("company_name")}
        </div>
      </div>
      <div class="w-full md:w-8/12 px-4">
      </div>
    </div>
  </div>
</footer>
