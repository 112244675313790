<script>
  import { Router, Route } from "svelte-routing";
    const sWebURL = VIS_SG_SERVICE_URL;

  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";
  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import FooterAdmin from "components/Footers/FooterAdmin.svelte";

  // pages for this layout
  import Introduction from "views/admin/Introduction.svelte";
  import PaymentInfo from "views/admin/PaymentInfo.svelte";
  import Login from "views/admin/Login.svelte";
  import ChangePassword from "views/admin/ChangePassword.svelte";
  import Register from "views/admin/Register.svelte";
  import Download from "views/admin/Download.svelte";
  import Contact from "views/admin/Contact.svelte";
  import CommonQA from "views/admin/CommonQA.svelte";
  

  export let location;
  export let admin = "";
</script>

 
  <div>
  <Sidebar location={location}/>
  <div class="relative md:ml-64 bg-blueGray-100">

    <AdminNavbar />
    <HeaderStats />
    <div class="px-4 md:px-10 mx-auto w-full -m-24">
      <Router url="admin">
        <Route path="/" component="{Introduction}" />
        <Route path="login" component="{Login}" />
        <Route path="register" component="{Register}" />
        <Route path="introduction" component="{Introduction}" />
        <Route path="changepassword" component="{ChangePassword}" />
        <Route path="download" component="{Download}" />
        <Route path="contact" component="{Contact}" />
        <Route path="commonQA" component="{CommonQA}" />
        <Route path="payment" component="{PaymentInfo}" />
      </Router>
      <FooterAdmin />
    </div>
 </div>
 </div>

