<script>
  import CardStats from "components/Cards/CardStats.svelte";
  import { tweened } from 'svelte/motion';
  import { onDestroy } from 'svelte'
      
  import { t, locale, locales } from "../../i18n";
  let max = 1000;
  let MaxUser = Math.floor(Math.random() * max);

  let Speed1 = Math.floor(Math.random() * 100);
  let Speed2 = Math.floor(Math.random() * 100);

  let speed = Speed1 + "." + Speed2 + "%"; 

//  export const location;

// import ChooseNode from "views/admin/ChooseNode.svelte";
$: time = new Date().toISOString().
replace(/T/, ' ').      // replace T with a space
replace(/\..+/, '').substring(0,10);     // delete the dot and everything after

 let sDate = $t("intro_date");
 let sNewUsers = $t("intro_new_users");
 let sSpeedUp = $t("intro_speed_up");
</script>




<!-- Header -->
<div class="relative bg-red-500 md:pt-20 pb-20 pt-12">
  <div class="px-4 md:px-10 mx-auto w-full">
    <div>
      <!-- Card stats -->
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="0"
            statTitle={time}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-emerald-500"
            statDescripiron="Date"
            statIconName="far fa-calendar-alt"
            statIconColor="bg-red-500"
          />
        </div>

        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="1"
            statTitle={MaxUser}
            statArrow="down"
            statPercent="3.48"
            statPercentColor="text-red-500"
            statDescripiron="Since last week"
            statIconName="far fa-chart-bar"
            statIconColor="bg-orange-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="2"
            statTitle={speed}
            statArrow="up"
            statPercent="12"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month"
            statIconName="fas fa-percent"
            statIconColor="bg-emerald-500"
          />
        </div>
      </div>
    </div>
  </div>
</div>
