<script>
    import { link } from "svelte-routing";
    import {sha256} from "js-sha256";
    import { t, locale, locales } from "../../i18n";
    import GlobalStore from 'components/store/GlobalStore.js'
    import { onDestroy } from 'svelte'
    import {user_email, user_password} from 'components/store/PersistentGlobalStore.js'    
  
    const sWebURL = VIS_SG_SERVICE_URL;
    let sFullPath = sWebURL;    
    
   
    let gUserEmail = $user_email;
    
//     if (gUserEmail == "") gUserEmail = "";
//     if (gUserName == "") gUserName = "";
   
     let gUserPassword = $user_password; 
     let gUserName = "";
   
  
      
      export let gSetting = {};
      const unsub = GlobalStore.subscribe(data => {
          gSetting = data;
      });
      
      let ihas_login = gSetting.has_login;
  
  
      let correlationId = new Date().toISOString().
     replace(/T/, ' ').      // replace T with a space
    replace(/\..+/, '').substring(0,10)     // delete the dot and everything after

  
      const changePassword = async () => {
  
        sFullPath = sWebURL + '/v1/user/update/'+gUserEmail;

        let jsonObject = {
                  'correlationId': correlationId,
                  'userEmail': gUserEmail,
                  'userPassword': gUserPassword
                };

        let sJsonStr = JSON.stringify(jsonObject);                      

        const settings = {
                method: 'POST',
                mode: "same-origin", // no-cors, *cors, same-origin
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                          'Content-Type': 'application/json'
                },
                body: sJsonStr
              };           
        console.log(sJsonStr);
        console.log(settings);
        let users_result = {};
        try
        {
          const res = await fetch(sFullPath, settings);
        const json = await res.json();
        users_result = json;
        }
        catch (e)
        {
          console.error("Received", e);
        }

          let sPasswordChanged = "";

          if (users_result.status == "200") 
        {
          sPasswordChanged = "TRUE";
        } 
        else 
        sPasswordChanged = "FALSE";


           if (sPasswordChanged == "TRUE")  
          {
              alert($t("success_change_pwd"));
          }
          else        
              alert($t("fail_change_pwd"));
  
  }
  
  
  
  function isEmailValid( value ) {
       if (value != null)
       {
           if (value.length > 7 && value.length < 50 ) return true;
           else return false;
       }
       return false;
    }

  function isPasswordValid( value ) {
        if (value != null)
        {
        if (value.length > 7 && value.length < 50 ) return true;
        else return false;
        }
        return false;
  }
  
  
  const submitForm = (event) => {
        const formData = new FormData(event.target);
  
  
        let error_flag = false;
  
        const sUserEmail = formData.get("user-email");
        console.log("sUserEMail:" +sUserEmail);

       
       if (!error_flag)
       if ( !isEmailValid( sUserEmail ) ) {
                    alert($t("incorrect_emai"));
                    error_flag = true;
          }

        
        const sPassword = formData.get("user-password");
        console.log("sPassword: "+sPassword);
  
        if ( !isPasswordValid( sPassword ) ) {
                alert($t("short_password"));
                error_flag = true;
        }

        const sConfirmPassword = formData.get("user-confirm-password");
        console.log("sConfirmPassword: "+sConfirmPassword);
  
        if ( sPassword != sConfirmPassword ) {
                alert($t("mismatch_password"));
                error_flag = true;
        }
  
  
        if ( !error_flag ) {

//              gUserPassword = sha256(sPassword);
              gUserPassword = sPassword;
              gUserEmail = sUserEmail;

              changePassword();
              console.log("Finished runnning changePassword");
        }
  
  }
  
  
  
  
    export let location;
  
  
  </script>

<div class="relative md:pt-22 pb-32 pt-12">

  <div class="flex flex-wrap">
    <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
     
    </div>
  </div>
</div>

  
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold uppercase">
                {@html  $t("ChangePassword")}
              </h6>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form on:submit|preventDefault={submitForm}>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-email"
                >
                {@html  $t("EMail")}
                </label>
                <input
                  id="grid-email"
                  type="email"
                  name="user-email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={gUserEmail}
                />
              </div>
  
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-password"
                >
                {@html  $t("password")}
                </label>
                <input
                  id="grid-password"
                  type="password"
                  name="user-password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={gUserPassword}
                />
              </div>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-confirm-password"
                >
                {@html  $t("ConfirmPassword")}
                </label>
                <input
                  id="grid-confirm-password"
                  type="password"
                  name="user-confirm-password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder={gUserPassword}
                />
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                {@html  $t("StartChangingPassword")}
                </button>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
  