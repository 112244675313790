export default {
    en: {
      "homepage.title": "Hello, World!",
      "homepage.welcome": "Hi <strong>{{name}}</strong>, how are you?",
      "homepage.time": "{{time}}",
      "homepage.product": "NeosNet",
      "homepage.accountManagement": "My Account",
      "homepage.payment": "Payment Info",
      "homepage.login": "Login",
      "homepage.register": "Register",
      "homepage.operations": "Common Operations",
      "homepage.introduction": "Introdctuion",
      "homepage.download": "Downloads",
      "homepage.commonQA": "Common Issue Q&A",
      "homepage.contact": "Contact Us",
      "homepage.mobile": "Mobile Device WebUI",
      "homepage.desktop": "Desktop  WebUI",
      "ChangePassword": "Change Password",
      "ConfirmPassword": "Confirm Password",
      "StartChangingPassword": "Start Changing Password",
      "Login": "Login",
      "RememberMe": "Remember Me",
      "SignIn": "Sign In",
      "register.confirmAge": "I am 21+ years old",
      "register.createAccount": "Create Account",
      "SignUp": "Sign Up",
      "EMail": "EMail",
      "Tel": "Tel",
      "Address": "Address",
      "Addr_Line1": "50 Choa Chu Kang North 7, ",
      "Addr_Line2": " #07-04 Tower A,  Regent Grove ",
      "Addr_Line3": " Singapore 689527 ",
      "register.FullName": "Full Name",
      "password": "password",
      "ForgotPassword": "Forgot Password",
      "CreateNewAccount": "Create An New Account",
      "register.IAgree": "I agree with the",
      "homepage.SoftwarePolicy": "Software Usage Policy",
      "qapage.question1": "1  Is it secure when I am browsing?",
      "qapage.question2": "2  Will encryption/decryption cause slowness?",
      "qapage.question3": "3  What local socket port will be used under Windows OS?",
      "qapage.question4": "4  What local socket port will be used under Android OS?",
      "qapage.anwser1": "Multi-tier encryption are applied. It is really secure.",
      "qapage.anwser2": "Server end is using a cloud based service. If any encryption/decryption slowness is detected, the system will allocate more resources to make it faster.",
      "qapage.anwser3": "Under Windows, socket 10080 will be used, working as a network proxy.",
      "qapage.anwser4": "No specific port will be used, it works as a VPN service.",
      "intro_p0": "NeosNet is a software to bypass ",
      "intro_p1": " The Great Firewall.",
      "intro_p2": "NeosNet uses a sophisticated design and infrastructure. Backend is cloud based. This gives service stability and scalability. While reaching the Great Firewall, all network data packets will be exactly the same as any other HTTPS data packets. All data packets are encrypted in a way like normal SSL/TLS communication.",
      "intro_p3": "It is difficult for people living in China to access popular websites like Google, Whatsapp, YouTube, FaceBook... It frustrated people when popular websites cannot be accessed. Especially for travellers.",
      "intro_p4": "Once installed NeosNet, If you are in Mainland China, you'll be able to access any popular websites. ",
      "intro_p5": "If you are not in Mainland China, you'll be able to continue enjoy China TV drama, sports meeting online show without any issue.",
      "intro_date": "DATE",
      "intro_new_users": "NEW USERS",
      "intro_speed_up": "DATA TRANSMITTED",
      "stat_performance": "TRANSMISSION SERVICE PERFORMANCE",
      "stat_downloads": "NUMBER OF DOWNLOADS",
      "company_name": "VisionInfo Technologies Pte Ltd",
      "copyright": "Copyright",
      "neosnet4android": "NeosNet for Android",  
      "version": "Version",
      "release_date": "Release Date",
      "net_communication": "Net Communication Encryption",
      "default_tcp_port": "Default TCP Port",
      "neosnet4windows": "NeosNet for Windows",
      "windows_os": "Windows OS",
      "default_proxy_port": "Default Local Proxy port",
      "Install": "Install",
      "Download": "Download",
      "Android_SDK": "Android SDK",
      "click": "Click",
      "here": " here ",
      "to_download": "to download the latest .Net Framework",
      "success_change_pwd": "Password changed successfully.",
      "fail_change_pwd": "Failed to change password.",
      "incorrect_email": "User Email is incorrect",
      "short_password": "Password must be at least 8 characters",
      "mismatch_password": "Password and Confirmation Password are different",
      "success_login": "login in successfully.",
      "failed_login": "Incorrect User Name or Password!",
      "register_success": " is registered successfully. ",
      "user_exists": " already exists. ",
      "register_failed": " is failed to be registered! ",
      "too_young": "You are too young to use this software.",
      "have_to_agree": "You have to agree with the Privacy Policy.",
      "short_user_name": "User Name must be at least 5 characters"
    },
    cn: {
      "homepage.title": "欢迎您!",
      "homepage.welcome": "您好, <strong>{{name}}</strong>, 最近好吗?",
      "homepage.time": "{{time}}",
      "homepage.product": "NeosNet",
      "homepage.accountManagement": "我的账户",
      "homepage.payment": "付款信息",
      "homepage.login": "登录",
      "homepage.register": "新用户注册",
      "homepage.operations": "基本操作",
      "homepage.introduction": "产品介绍",
      "homepage.download": "下载",
      "homepage.commonQA": "常见问题问答",
      "homepage.contact": "联系我们",
      "homepage.mobile": "手机版页面",
      "homepage.desktop": "桌面版页面",
      "ChangePassword": "更换密码",
      "ConfirmPassword": "确认密码",
      "StartChangingPassword": "开始更换密码",
      "Login": "登录",
      "RememberMe": "记住我以便下次登录",
      "SignIn": "开始登录",
      "ForgotPassword": "忘记密码",
      "CreateNewAccount": "重新建立账户",
      "register.confirmAge": "我的年龄是21周岁或以上",
      "register.createAccount": "建立账户",
      "SignUp": "注册登记",
      "EMail": "电邮",
      "Tel": "电话",
      "Address": "地址",
      "Addr_Line1": "50 蔡厝港北七道, ",
      "Addr_Line2": " #07-04 大厦A, 丽晶花园 ",
      "Addr_Line3": " 新加坡邮区 689527 ",
      "register.FullName": "姓名",
      "password": "密碼",
      "register.IAgree": "我同意",
      "homepage.SoftwarePolicy": "软件使用条款",
      "qapage.question1": "1  当我浏览网页时， 有数据安全问题吗？",
      "qapage.question2": "2  加密//解密会引起速度变慢吗?",
      "qapage.question3": "3  在视窗操作系统下会使用哪一个本地端口?",
      "qapage.question4": "4  在安卓操作系统下会使用哪一个本地端口?",
      "qapage.anwser1": "软件使用多层加密系统， 非常安全.",
      "qapage.anwser2": "服务器端使用云技术， 一旦侦测任何因为加密/解密引起的速度下降，系统会自动分配更多的资源使得速度提升 .",
      "qapage.anwser3": "会使用端口10080",
      "qapage.anwser4": "不使用通讯端口， 它是一个虚拟网络服务.",
      "intro_p0": "NeosNet 软件用于翻越 ",
      "intro_p1": " 防火墙.",
      "intro_p2": "NeosNet 采用复杂的软件逻辑和硬件平台. 后端是云平台，保证了系统的扩张性和稳定性。 对于防火墙来说， 所有数据包都和普通HTTPS的数据包一样. 加密方法也和SSL/TLS的加密方法一样.",
      "intro_p3": "生活在中国大陆地区的人民很难直接访问常用的网站例如 Google, Whatsapp, YouTube, FaceBook... 给人们的生活带来很多不便. 特别是到大陆旅游的旅客.",
      "intro_p4": "安装了NeosNet以后, 在中国大陆就可以访问任何网站. ",
      "intro_p5": "对于生活在中国大陆地区以外的人们，如果想观看只在内地网上播放的电视剧， 体育节目。仍然可以以内地的IP地址欣赏节目.",
      "intro_date": "日期",
      "intro_new_users": "新增用户",
      "intro_speed_up": "传输数据量",
      "stat_performance": "传输服务表现",
      "stat_downloads": "下载次数",
      "company_name": "视慧科技有限公司",
      "copyright": "版权所有",
      "neosnet4android": "NeosNet 安卓版",  
      "version": "版本",
      "release_date": "发行日期",
      "net_communication": "网络通讯加密",
      "default_tcp_port": "默认传输端口",
      "neosnet4windows": "NeosNet 微软视窗版",
      "windows_os": "Windows 操作系统",
      "default_proxy_port": "默认上网代理本地网络端口",
      "Install": "安装",
      "Download": "下载",
      "Android_SDK": "安卓软件包",
      "click": "按",
      "here": " 这里 ",
      "to_download": "下载最新的.Net Framework版本",
      "success_change_pwd": "口令已成功更改.",
      "fail_change_pwd": "更改口令失败.",
      "incorrect_email": "电邮不正确",
      "short_password": "密码太短",
      "mismatch_password": "密码不匹配",
      "success_login": "登录成功.",
      "failed_login": "电邮或密码不对!",
      "register_success": " 注册成功. ",
      "user_exists": " 已经登记过. ",
      "register_failed": " 注册失败! ",
      "too_young": "您年龄太小， 不能使用该软件.",
      "have_to_agree": "您不同意软件条款， 因而不能使用该软件.",
      "short_user_name": "用户名太短"
    },
  };
  