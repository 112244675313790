<script>
  import { t, locale, locales } from "../../i18n";
  // make dynamic date to be added to footer
  let date1 = new Date().getFullYear();
  let date = '2005 - ' + date1;

</script>

<head runat="server">
        <title>{@html  $t("company_name")}</title>
        <style> 
    
        #rcorners3 {
          border-radius: 0px;
          background: url(imgs/VisionInfo_Logo5.png);
          background-position: left top;
          background-repeat: no-repeat;
          padding: 0px; 
          width: 80px;
          height:80px;  
        }
        #rcorners4 {
          border-radius: 20px;
          background: url(imgs/Jurong.jpg);
          background-position:left
          background-repeat: no-repeat;
          padding: 0px; 
          width: 260px;
          height:260px;  
        }
        img {
             border-radius: 50%;
        }
        #grad1 {
             height: 20px;
            background-color: #0072C6; /* For browsers that do not support gradients */
            background-image: linear-gradient(#0072C6, white); /* Standard syntax (must be last) */
        }
            #grad2 {
                height: 40px;
                background-color: #0072C6; /* For browsers that do not support gradients */
                background-image: linear-gradient(white, #0072C6); /* Standard syntax (must be last) */
            }
      
        p.serif {
            font-family: "Times New Roman", Times, serif;
        }
    
            p.sansserif {
                font-family: Arial, Helvetica, sans-serif;
            }
    
            .nav {
                background-color: #006CC0;
                list-style-type: none;
                text-align: center;
                margin: 0;
                padding: 0;
            }
    
        .nav li {
            display: inline-block;
            font-size: 24px;
            padding: 20px;
            }
    
        
        </style>
    
   
    
    </head>
    
<div id="grad1"></div>


<div class="relative md:pt-32 pb-22 pt-12">

<div class="flex flex-wrap">
  <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
   
  </div>
</div>
</div>


<table  bgcolor=#FFFFFF width="100%">
 <tr style="color:black">

     <td>
         <img src="../assets/img/JuRong.jpg" alt="JuRong" width="300" height="300">
     </td>
     <td>

     </td>
     <td>
             <table>
                 <tr>
                     <td>
                         {@html  $t("EMail")}:
                     </td>
                     <td>
                         support@vis.sg
                     </td>
                 </tr>
                 <tr></tr>
                 <tr>
                     <td>
                         {@html  $t("Tel")}::
                     </td>
                     <td>
                         88390027
                     </td>
                 </tr>
                 <tr></tr>
                 <tr>
                     <td>
                         {@html  $t("Address")}:
                     </td>
                     <td>
                         {@html  $t("Addr_Line1")}:
                     </td>
                 </tr>
                 <tr>
                     <td>
                        
                     </td>
                     <td>
                         {@html  $t("Addr_Line2")}:
                     </td>
                 </tr>
                 <tr>
                     <td>
                        
                     </td>
                     <td>
                         {@html  $t("Addr_Line3")}:
                     </td>
                 </tr>


             </table>

     </td>


 </tr>


</table>

