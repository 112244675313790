import { writable } from 'svelte/store';

const GlobalStore = writable(
  {
    cur_host_name: '',
    cur_user_name: '',
    cur_user_email: '',
    cur_user_password: '',
    current_page: '',
    has_login: 0
  }
);

// const stored_iNumOfSampling = localStorage.getItem("num_chart_sampling");
// export const iSampling = writable(stored_iNumOfSampling);

// iSampling.subscribe(value => {
//     localStorage.setItem("num_chart_sampling", GlobalStore.num_chart_sampling);
// });


const stored_has_login = localStorage.getItem("has_login");
export const has_login = writable(stored_has_login);

has_login.subscribe(value => {
    localStorage.setItem("has_login", GlobalStore.has_login);
});


export default GlobalStore;
