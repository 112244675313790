<!-- App.svelte -->
<script>
  import { Router, Link, Route } from "svelte-routing";
  import { t, locale, locales } from "./i18n";

  import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  import Landing from "./views/Landing.svelte";
  import Profile from "./views/Profile.svelte";


  // Admin Layout

  $: time = new Date().toISOString().
  replace(/T/, ' ').      // replace T with a space
  replace(/\..+/, '')     // delete the dot and everything after


//let desktop = false;
//  if (navigator.userAgent.match(/Android/i)
//         || navigator.userAgent.match(/webOS/i)
//         || navigator.userAgent.match(/iPhone/i)
//         || navigator.userAgent.match(/iPad/i)
//         || navigator.userAgent.match(/iPod/i)
//         || navigator.userAgent.match(/BlackBerry/i)
//         || navigator.userAgent.match(/Windows Phone/i)) {
//            desktop = false ;
//         } else {
//            desktop = true ;
//         }

//desktop = false;


  
  export let url = "";

  
</script>

 <Router url="{url}">
    
    
    <!-- main portal  -->
    
    <Route path="admin/*admin" component="{Admin}" />
    <!-- auth layout -->
    <Route path="auth/*auth" component="{Auth}" />
        <!-- no layout pages -->
    <Route path="landing" component="{Landing}" />
    <Route path="profile" component="{Profile}" />
    <!--<Route path="/" component="{Index}" /> -->
    <Route path="/" component="{Admin}" />
    
  
</Router>

