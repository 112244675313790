<script>
	import ChatMessage from './ChatMessage.svelte';
	import TodayDivider from './TodayDivider.svelte';
	import Fa from 'svelte-fa';
	import { faUsers, faCompressArrowsAlt, faComments, faEnvelope } from '@fortawesome/free-solid-svg-icons';
	import { t, locale, locales } from "../../i18n";

	$: time = new Date().toISOString().
  replace(/T/, ' ').      // replace T with a space
  replace(/\..+/, '').substring(0,10)     // delete the dot and everything after


	let nameMe='';
	let profilePicMe='../assets/img/Linda.png';

	let nameChatPartner='';
	let profilePicChatPartner='../assets/img/SPACE089.png';
	let messages = [];

	messages = [{"messageId":416,"message":"qapage.question1", "timestamp":1587139022488.826,"sentByMe":false,"timeRead":1587139025367.015},
                {"messageId":417,"message":"qapage.anwser1","timestamp":1587139034294.678,"sentByMe":true,"timeRead":1587139048713.461},
				{"messageId":418,"message":"qapage.question2","timestamp":1587139047495.052,"sentByMe":false,"timeRead":1587139048713.461},
				{"messageId":419,"message":"qapage.anwser2","timestamp":1587139312376.663,"sentByMe":true,"timeRead":1587139336397.5078},
				{"messageId":420,"message":"qapage.question3","timestamp":1587139349155.217,"sentByMe":false,"timeRead":1587139359024.353},
				{"messageId":426,"message":"qapage.anwser3","timestamp":1587577393781.811,"sentByMe":true,"timeRead":1587686514958.049},
				{"messageId":427,"message":"qapage.question4","timestamp":1587577411018.97,"sentByMe":false,"timeRead":1587686514958.049},
				{"messageId":431,"message":"qapage.anwser4","timestamp":1587652540004.281,"sentByMe":true,"timeRead":1587686514958.049}];



	//   messages = [{"messageId":416,"message":"1  Is it secure when I am browsing?","timestamp":1587139022488.826,"sentByMe":false,"timeRead":1587139025367.015},
	//                 {"messageId":417,"message":"Multi-tier encryption are applied. It is really secure.","timestamp":1587139034294.678,"sentByMe":true,"timeRead":1587139048713.461},
	// 				{"messageId":418,"message":"2  Will encryption/decryption cause slowness?","timestamp":1587139047495.052,"sentByMe":false,"timeRead":1587139048713.461},
	// 				{"messageId":419,"message":"Server end is using a cloud based service. If any encryption/decryption slowness is detected, the system will allocate more resources to make it faster.","timestamp":1587139312376.663,"sentByMe":true,"timeRead":1587139336397.5078},
	// 				{"messageId":420,"message":"3  What local socket port will be used under Windows OS?","timestamp":1587139349155.217,"sentByMe":false,"timeRead":1587139359024.353},
	// 				{"messageId":426,"message":"Under Windows, socket 10080 and 10081 will be used, working as a network proxy.","timestamp":1587577393781.811,"sentByMe":true,"timeRead":1587686514958.049},
	// 				{"messageId":427,"message":"4  What local socket port will be used under Android OS?","timestamp":1587577411018.97,"sentByMe":false,"timeRead":1587686514958.049},
	// 				{"messageId":431,"message":"No specific port will be used, it works as a VPN service.","timestamp":1587652540004.281,"sentByMe":true,"timeRead":1587686514958.049}];

let todayMessages =[
];

</script>



<style>
	.direct-chat .card-body {
		overflow-x: hidden;
		padding: 0;
		position: relative;
	}

	.direct-chat-messages {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		height: 400px;
		overflow: auto;
		padding: 10px;
		transition: -webkit-transform .5s ease-in-out;
		transition: transform .5s ease-in-out;
		transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
	}

	.contacts-img {
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
	.contacts-name {
		margin-left: 15px;
		font-weight: 600;
	}
</style>

<svelte:head>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
</svelte:head>

<div class="relative md:pt-32 pb-22 pt-12">

	<div class="flex flex-wrap">
	  <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
	   
	  </div>
	</div>
	</div>
	

<div class="card card-danger direct-chat direct-chat-danger">
    <!--div class="card-header">
        <div class="card-tools d-flex">
            <img class="contacts-img" src={profilePicChatPartner} alt="profilePic">
            <span class="contacts-name">{nameChatPartner}</span>
            <span class="mr-auto"></span>
            <button type="button" class="btn btn-tool" title="Contacts"><Fa icon={faUsers} /></button>
            <button type="button" class="btn btn-tool"><Fa icon={faCompressArrowsAlt} /></button>
        </div>
    </div-->
    <div class="card-body">
        <div class="direct-chat-messages">
            {#each messages as message}
                <ChatMessage
                    nameMe = {nameMe}
                    profilePicMe = {profilePicMe}
                    nameChatPartner = {nameChatPartner}
                    profilePicChatPartner = {profilePicChatPartner}
										message={ $t(message.message,{ time })}
									  timestamp={message.timestamp}
										sentByMe={message.sentByMe} 
										timeRead={message.timeRead}
										/>
            {/each}
					
					<!--TodayDivider></TodayDivider-->
					 {#each todayMessages as todayMessage}
                <ChatMessage
                    nameMe = {nameMe}
                    profilePicMe = {profilePicMe}
                    nameChatPartner = {nameChatPartner}
                    profilePicChatPartner = {profilePicChatPartner}
										message={todayMessage.message}
									  timestamp={todayMessage.timestamp}
										sentByMe={todayMessage.sentByMe} 
										timeRead={todayMessage.timeRead}										
										isToday={true} 														 
										/>
            {/each}
					
        </div>
    </div>
    <!--div class="card-footer">
        <div class="input-group">
            <input type="text" placeholder="Type Message ..." class="form-control">
            <span class="input-group-append">
                <button type="button" class="btn btn-primary">Send</button>
            </span>
        </div>
    </div-->
</div>