<script>
 
    import {user_name,user_email} from 'components/store/PersistentGlobalStore.js'
    import { onDestroy } from 'svelte'
    import {sha256} from "js-sha256";
    import { t, locale, locales } from "../../i18n";
    import { redirect } from '@sveltejs/kit';

    const sWebURL = VIS_SG_SERVICE_URL;
    let sFullPath = sWebURL;    
    let gUserEmail = $user_email;
    let gUserName = $user_name;
    
     if (gUserEmail == "") gUserEmail = "Email";
     if (gUserName == "") gUserName = "Full Name";
   
     let gUserPassword = ""; 
   
   
     export let location;
   
     const registerUser = async () => {
   
           sFullPath = sWebURL + '/v1/info/set/REGISTER_USER';
           let users_result = {};
           try
           {
               const res = await fetch(sFullPath, {
               method: 'POST',
               headers: {
                     'Content-Type': 'application/json'
                       },
                body: JSON.stringify({
                       "category":"REGISTER_USER",
                       "infoSet": {
                                     "USER_EMAIL": gUserEmail,
                                     "USER_NAME": gUserName,
                                     "USER_PASSWORD": gUserPassword
                               }   
                      })
                 });
   
              const json = await res.json()
              console.log(json);
             users_result = json;
         }
        catch (e)
        {
               console.error("Received", e);
        }
   
       let sUserInserted = "";
   
       if (users_result.status == 0) {
   
         if (users_result.result != null)
         {
             if (users_result.result.length > 0)
             {
               users_result.result.forEach(user=>
               {
                 sUserInserted = user.infoSet.USER_INSERTED;   
               }
               );
             }	
           }          
       } 
       else {
             throw new Error(nodes);
         }
   
       if (sUserInserted == "TRUE")  
       {
               alert("User "+gUserName + " is registered successfully.");
               user_name.set(gUserName);   
        }
        else if (sUserInserted == "EXISTS")  
       {
               alert("User "+gUserName + " already exists.");
               user_name.set(gUserName);   
        }
       else        
               alert("User "+gUserName + " is failed to be registered!");
   
     }
   
   
     function isUserNameValid( value ) {
       if (value != null)
       {
           if (value.length > 4 && value.length < 50 ) return true;
           else return false;
       }
       return false;
    }
   
    function isPasswordValid( value ) {
       if (value != null)
       {
           if (value.length > 7 && value.length < 50 ) return true;
           else return false;
       }
       return false;
    }

    function isEmailValid( value ) {
       if (value != null)
       {
           if (value.length > 7 && value.length < 50 ) return true;
           else return false;
       }
       return false;
    }

     const submitForm = (event) => {
   
       const formData = new FormData(event.target);
       
   
       let error_flag = false;
   
       const sUserName = formData.get("user-name");
       console.log("sUserName:" +sUserName);
   
       if ( !isUserNameValid( sUserName ) ) {
                    alert("User Name be at least 5 characters");
                    error_flag = true;
          }
   
       const sPassword = formData.get("user-password");
       console.log("sPassword: "+sPassword);
   
       if ( !isPasswordValid( sPassword ) ) {
                    if (!error_flag) alert("Password must be at least 8 characters");
                    error_flag = true;
          }

        const sUserAge =  formData.get("user-age");
        if (sUserAge == null)
        {
          if (!error_flag) alert("You are too young to use this software.")
           error_flag = true;
        }

   
        const sUserAgree =  formData.get("user-agree");
        if (sUserAgree == null)
        {
          if (!error_flag) alert("You have to agree with the Privacy Policy.")
           error_flag = true;
        }
   
        if ( !error_flag ) {
   
              gUserName = sUserName;
              gUserPassword = sha256(sPassword);
   
               registerUser();
   
               console.log("Finished runnnint registerUser()");
            }
   
       
    }
   
   </script>
   
   <div class="container mx-auto px-4 h-full">
     <div class="flex content-center items-center justify-center h-full">
       <div class="w-full lg:w-6/12 px-4">
         <div
           class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
         >
           <div class="rounded-t mb-0 px-6 py-6">
             <div class="text-center mb-3">
               <h6 class="text-blueGray-500 text-sm font-bold">
                 Sign up
               </h6>
             </div>
             <hr class="mt-6 border-b-1 border-blueGray-300" />
           </div>
           <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
             <form on:submit|preventDefault={submitForm}>
               <div class="relative w-full mb-3">
                 <label
                   class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                   for="grid-name"
                 >
                   Email
                 </label>
                 <input
                   id="grid-name"
                   type="email"
                   name="user-email"
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   placeholder={gUserEmail}
                 />
               </div>

               <div class="relative w-full mb-3">
                 <label
                   class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                   for="grid-name"
                 >
                   Full Name
                 </label>
                 <input
                   id="grid-name"
                   type="Full Name"
                   name="user-full-name"
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   placeholder={gUserName}
                 />
               </div>
   
               <div class="relative w-full mb-3">
                 <label
                   class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                   for="grid-password"
                 >
                   Password
                 </label>
                 <input
                   id="grid-password"
                   type="password"
                   name="user-password"
                   class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                   placeholder="Password"
                 />
               </div>

               <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckAge"
                    type="checkbox"
                    name="user-age"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    {@html  $t("register.confirmAge")}
                  </span>
                </label>
              </div>
  

   
               <div>
                 <label class="inline-flex items-center cursor-pointer">
                   <input
                     id="customCheckLogin"
                     type="checkbox"
                     name="user-agree"
                     class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                   />
                   <span class="ml-2 text-sm font-semibold text-blueGray-600">
                     {@html  $t("register.IAgree")}
                     <a href="#pablo" on:click={(e) => e.preventDefault()} class="text-red-500">
                      {@html  $t("homepage.SoftwarePolicy")}
                     </a>
                   </span>
                 </label>
               </div>
   
               <div class="text-center mt-6">
                 <button
                   class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                   type="submit"
                 >
                   {@html  $t("register.createAccount")}
                    </button>
               </div>
             </form>
           </div>
         </div>
       </div>
     </div>
   </div>
   