import { writable } from 'svelte/store';


const stored_cur_host_name = localStorage.getItem("cur_host_name" || "");
export const cur_host_name = writable(stored_cur_host_name);

cur_host_name.subscribe(value => {
    if (value == null)
        localStorage.setItem("cur_host_name", '');
     else
        localStorage.setItem("cur_host_name", value);
    console.log("Saved:"+value);
});


const stored_user_name = localStorage.getItem("user_name" || "");
export const user_name = writable(stored_user_name);

user_name.subscribe(value => {
    if (value == null)
        localStorage.setItem("user_name", '');
     else
        localStorage.setItem("user_name", value);
    console.log("Saved:"+value);
});

const stored_user_email = localStorage.getItem("user_email" || "");
export const user_email = writable(stored_user_email);

user_email.subscribe(value => {
    if (value == null)
        localStorage.setItem("user_email", '');
     else
        localStorage.setItem("user_email", value);
    console.log("Saved:"+value);
});

const stored_user_password = localStorage.getItem("user_password" || "");
export const user_password = writable(stored_user_password);

user_password.subscribe(value => {
    if (value == null)
        localStorage.setItem("user_password", '');
     else
        localStorage.setItem("user_password", value);
    console.log("Saved:"+value);
});

