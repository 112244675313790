<script>
  // core components
  import { t, locale, locales } from "../../i18n";
  export let location;

//  const sWindowsDownloadURL = WINDOWS_DOWNLOAD_PATH;
//  const sAndroidDownloadURL = ANDROID_DOWNLOAD_PATH;

    const sWindowsDownloadURL = "../../resource/NeosNetClient_Win_Install_Ver3_100.zip"
    const sAndroidDownloadURL = "../../resource/NeosNet.VIS.SG-v3.1.2-beta-260030102-release.apk"

</script>


<div class="relative md:pt-32 pb-32 pt-12">

  <div class="flex flex-wrap">
    <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
     
    </div>
  </div>
</div>
<!--div>
  <table  bgcolor=#FFFFFF width="100%" height ="50%"  cellspacing="0"   border="0">

    <tr style="height:10px;color:black;">
      <td class ="auto-style4">
          <h4>{@html  $t("neosnet4android")}</h4>
      </td>
        <td  style="height:10px;color:red;">
            <h2><a href="{sAndroidDownloadURL}" class="text-red-500">{@html  $t("Install")}</a></h2>
        </td>

          <td class ="auto-style4"></td>
  </tr>

    <tr>
      <td class ="auto-style2">
          <strong>{@html  $t("version")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>Ver 2.01</strong>
      </td>
        <td class ="auto-style4"></td>
  </tr>
     
    <tr style="height:10px;color:black;">
      <td class="auto-style2">
          {@html  $t("Android_SDK")}</td>
      <td class="auto-style3">
          SDK Level 26--34</td>
          <td class ="auto-style4"></td>
  </tr>

    <tr style="height:10px;color:black;">
      <td class ="auto-style2">
          <strong>{@html  $t("release_date")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>01/10/2023</strong>
      </td>
         <td class ="auto-style4"></td>
  </tr>

   <tr style="height:10px;color:black;">
      <td class ="auto-style2">
          <strong>{@html  $t("net_communication")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>TLS 1.3</strong></td>
         <td class ="auto-style4"></td>
  </tr>
  <tr style="height:10px;color:black;">
      <td class ="auto-style2">
          <strong>{@html  $t("default_tcp_port")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>443</strong>
      </td>
        <td class ="auto-style4"></td>
  </tr>
  <tr style="height:10px;color:black;">
      <td class ="auto-style2" colspan="3">
    
      </td>
  </tr>

</table>
</div-->

<!--div class="relative md:pt-12 pb-12 pt-12">

  <div class="flex flex-wrap">
    <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
     
    </div>
  </div>
</div-->


<div>
  <table  bgcolor=#FFFFFF width="100%" height ="50%"  cellspacing="0"   border="0">
 
    <tr style="height:10px;color:black;">
      <td class ="auto-style4">
          <h4>              {@html  $t("neosnet4windows")}</h4>
      </td>

      <td  style="height:10px;color:red;">
        <h2><a href="{sWindowsDownloadURL}" class="text-red-500">{@html  $t("Download")}</a></h2>
     </td>
     <td class ="auto-style4"></td>
  </tr>

    <tr>
      <td class ="auto-style2">
          <strong>{@html  $t("version")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>3.100</strong>
      </td>
        <td class ="auto-style4"></td>
  </tr>
    <tr>
     <td class ="auto-style2">
          <strong>Microsoft .Net Framework</strong>
      </td>
      <td class="auto-style3">
          <strong>.Net Framework 4.80</strong>
      </td>
           
      <td class ="auto-style4"><div style="text-align: left">{@html  $t("click")}<a target=”_blank”  href="https://dotnet.microsoft.com/download/dotnet-framework/net48"> <strong>{@html  $t("here")}<strong></a>  {@html  $t("to_download")}</div></td>
  </tr>

      
    <tr style="height:10px;color:black;">
      <td class="auto-style2">
          <strong>{@html  $t("windows_os")}</strong>
      </td>
      <td class="auto-style3">
          <strong>Windows 10 / Windows 11, 32 bit/ 64 bit</strong>
      </td>
          <td class ="auto-style4"></td>
  </tr>

    <tr style="height:10px;color:black;">
      <td class ="auto-style2">
          <strong>{@html  $t("release_date")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>25/01/2020</strong>
      </td>
         <td class ="auto-style4"></td>
  </tr>

   <tr style="height:10px;color:black;">
      <td class ="auto-style2">
          <strong>{@html  $t("net_communication")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>TLS 1.3</strong>
      </td>
         <td class ="auto-style4"></td>
  </tr>
  <tr style="height:10px;color:black;">
      <td class ="auto-style2">
          <strong>{@html  $t("default_tcp_port")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>443</strong>
      </td>
        <td class ="auto-style4"></td>
  </tr>
  <tr style="height:10px;color:black;">
      <td class ="auto-style2">
          <strong>{@html  $t("default_proxy_port")}</strong>
      </td>
      <td class ="auto-style3">
          <strong>10080</strong>
      </td>
       <td class ="auto-style4"></td>
  </tr>

  <tr style="height:10px;color:black;">
      <td class ="auto-style2" colspan="3">
    
      </td>
  </tr>

</table>





</div>
